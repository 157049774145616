import { ProjectInterface } from "../components/dapp-store/DAppStorePage";

export const groupByKey = (list: any[], key: string) =>
  list?.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {});

export const getAllNetworks = (projects: ProjectInterface[]) => {
  const networks = new Set<string>();
  projects.map((project) => {
    networks.add(project.fields.deploy);
  });
  return [...networks.values()];
};

type FilterProjects = {
  projects: ProjectInterface[];
  network: string | null;
  category: string | null;
  search: string;
};
export const filterProjects = ({
  projects,
  network,
  category,
  search,
}: FilterProjects): ProjectInterface[] => {
  return projects
    .filter((project) => (network ? project.fields.deploy === network : true))
    .filter((project) =>
      category === "Liquid Staking"
        ? project.fields.compatible_liquid_staking
        : category
        ? project.fields.categories?.indexOf(category) !== -1
        : true
    )
    .filter((project) => {
      const searchQuery = project.fields.project_name?.toLowerCase();
      return searchQuery?.includes(search?.toLowerCase());
    });
};

export const generateSlug = (text: string) =>
  text
    .toString()
    .normalize("NFKD") // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\_/g, "-") // Replace _ with -
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/\-$/g, ""); // Remove trailing -
