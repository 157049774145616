import React from "react";
import ReactModal from "react-modal";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

import CloseIcon from "../../../static/images/icons/dapp-store/close.inline.svg";
import classNames from "classnames";

function BaseModal({
  isOpen,
  onClose,
  children,
  overlayComponent,
  isLoading,
  isSubmitted,
  isDetailsModal,
}: {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  overlayComponent?: React.ReactNode; // if it's carousel modal
  isLoading?: boolean;
  isSubmitted?: boolean;
  isDetailsModal?: boolean;
}) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-blue-bg-dark/90 z-[60]" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto z-[70]">
          <div className="flex h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  "flex items-center justify-center",
                  isDetailsModal ? "relative w-full h-full" : "max-sm:h-full"
                )}
              >
                <div
                  className={classNames(
                    "flex flex-col h-full relative md:max-w-[450px] lg:max-w-[600px] bg-blue-filter overflow-hidden rounded-md shadow-[0px_0px_8px_rgba(255,255,255,0.32)] text-left",
                    isDetailsModal
                      ? "md:h-[65vh] lg:h-[85vh] lg:max-h-[860px]"
                      : "md:h-[85vh] px-16 md:px-24 py-48 pb-65 md:py-64 md:pb-90",
                    isSubmitted && "h-auto md:h-auto w-screen"
                  )}
                >
                  {children}
                  {/* Close Icon */}
                  {!isLoading && (
                    <button
                      className="w-[32px] h-[32px] hover:cursor-pointer absolute top-[24px] right-[24px] z-10 opacity-80 transition-opacity hover:opacity-100"
                      aria-label="Close modal"
                      onClick={onClose}
                    >
                      <CloseIcon />
                    </button>
                  )}
                </div>
                {overlayComponent}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default BaseModal;
