import classNames from "classnames";
import React, { Fragment } from "react";
import { CategoryDetail } from "./DAppStorePage";
import { Listbox, Transition } from "@headlessui/react";
import ArrowDownIcon from "../../../static/images/icons/dapp-store/arrow-down.inline.svg";

type FilterProps = {
  networks: string[] | null;
  onNetworkClick: (network: string) => void;
  currentNetwork: string | null;
  categories: Record<string, CategoryDetail[]> | null;
  onCategoryClick: (category: string) => void;
  currentCategory: string | null;
  searchQuery: string;
  handleSearchQuery: (string: string) => void;
};

const Filter = ({
  networks,
  onNetworkClick,
  currentNetwork,
  categories,
  onCategoryClick,
  currentCategory,
  searchQuery,
  handleSearchQuery,
}: FilterProps) => {
  return (
    <>
      <div className="bg-blue-filter rounded-lg mb-16 flex">
        {networks &&
          networks.map((network) => (
            <button
              key={network}
              onClick={() => onNetworkClick(network)}
              className={classNames(
                "p-8",
                "flex-1",
                "rounded-lg",
                network === currentNetwork
                  ? "bg-orange text-white"
                  : "text-disabled hover:text-white"
              )}
            >
              {network}
            </button>
          ))}
      </div>
      <div className="mb-28">
        <label htmlFor="search" className="sr-only">
          Search projects
        </label>
        <input
          type="text"
          id="search"
          value={searchQuery}
          onChange={(event) => handleSearchQuery(event.target.value)}
          placeholder="Search project ..."
          className="bg-blue-filter placeholder:text-gray-400 text-white border border-gray-600 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-2 pl-14 h-[44px]"
        />
      </div>
      <div className="hidden md:flex md:flex-col">
        <button
          onClick={() => onCategoryClick("")}
          className={classNames("filter-item", !currentCategory && "active")}
        >
          All categories
        </button>
        {Object.entries(categories ?? {}).map(([titleCategory, categoryList]) => (
          <div className="mt-16" key={titleCategory}>
            <p className="text-10 md:text-12 text-disabled font-semibold uppercase mt-8">
              {titleCategory}
            </p>
            <div className="space-y-8">
              {categoryList?.map((category) => (
                <button
                  key={category.id}
                  onClick={() => onCategoryClick(category.name)}
                  className={classNames(
                    "filter-item mt-16",
                    category.name === currentCategory && "active"
                  )}
                >
                  {category.name}
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>
      {/* Mobile version */}
      <SelectCategory
        categories={categories}
        onCategoryClick={onCategoryClick}
        currentCategory={currentCategory}
      />
    </>
  );
};

export default Filter;

type SelectCategory = Pick<FilterProps, "categories" | "onCategoryClick" | "currentCategory">;

function SelectCategory({ categories, onCategoryClick, currentCategory }: SelectCategory) {
  return (
    <Listbox as="div" className="relative mb-20" value={currentCategory} onChange={onCategoryClick}>
      {({ open }) => (
        <div className="relative mt-1">
          <Listbox.Button className="md:hidden filter-item active py-10 px-24 text-disabled transition-colors bg-blue-filter rounded-lg">
            <span className="block truncate">{currentCategory || "All Categories"}</span>
            <span
              className={classNames(
                "pointer-events-none absolute inset-y-0 right-10 flex items-center pr-2 transition-transform duration-75",
                open && "rotate-180"
              )}
            >
              <ArrowDownIcon className="h-20 w-20" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-5 w-full overflow-auto shadow-2xl rounded-md max-h-320 bg-[#131a20] z-10 py-2">
              <Listbox.Option
                className={classNames(
                  "filter-item text-16 bg-[#131a20] text-disabled",
                  !currentCategory && "text-white active"
                )}
                value=""
              >
                <span
                  className={`block truncate ${!currentCategory ? "font-medium" : "font-normal"}`}
                >
                  All categories
                </span>
              </Listbox.Option>
              {Object.entries(categories ?? {}).map(([titleCategory, categoryList]) => (
                <div key={titleCategory} className="py-8 border-t border-blue-bg-dark">
                  <span className="py-16 pb-18 px-24 text-10 text-disabled font-semibold uppercase">
                    {titleCategory}
                  </span>
                  {categoryList?.map((category) => (
                    <Listbox.Option
                      key={category.id}
                      className={classNames(
                        "filter-item text-16 bg-[#131a20] text-disabled",
                        category.name === currentCategory && "text-white active"
                      )}
                      value={category.name}
                    >
                      <span
                        className={`block truncate ${
                          category.name === currentCategory ? "font-medium" : "font-normal"
                        }`}
                      >
                        {category.name}
                      </span>
                    </Listbox.Option>
                  ))}
                </div>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
