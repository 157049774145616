import classNames from "classnames";
import React from "react";
import * as Tooltip from "@radix-ui/react-tooltip";

interface IconWithTooltipProps {
  icon: React.ReactNode;
  tooltipContent: React.ReactNode;
  iconClassNames?: string;
}

const IconWithTooltip = ({ icon, tooltipContent, iconClassNames }: IconWithTooltipProps) => {
  return (
    <Tooltip.Provider delayDuration={0}>
      <Tooltip.Root>
        <Tooltip.Trigger>
          <div
            className={classNames(
              "tooltipIcon w-16 h-16 fill-white opacity-60 transition-opacity hover:opacity-100",
              iconClassNames
            )}
          >
            {icon}
          </div>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content className="z-[1000] inline-flex items-center" sideOffset={5}>
            <span className="block px-8 py-5 bg-blue-bg-dark/[0.8] text-12 rounded-md leading-[15px] text-white text-center font-[500] shadow-[0_0_10px_hsl(0deg_0%_100%/28%)]">
              {tooltipContent}
            </span>
            <Tooltip.Arrow className="relative fill-current text-blue-bg-dark/[0.8]" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default IconWithTooltip;
