import React from "react";
import classNames from "classnames";

import { ProjectAttributes, ProjectInterface } from "./DAppStorePage";

import VerifiedIcon from "../../../static/images/icons/dapp-store/verified.inline.svg";
import MobileLinkIcon from "../../../static/images/icons/dapp-store/mobile-link.inline.svg";
import DefiLamaIcon from "../../../static/images/icons/dapp-store/defilama-link.inline.svg";

import IconWithTooltip from "./IconWithTooltip";

export interface ProjectProps extends ProjectInterface {
  onProjectClick?: (slug: string) => void;
}

const Project = ({ fields, slug, onProjectClick }: ProjectProps) => {
  return (
    <li
      className={classNames(
        "relative",
        "bg-blue-filter",
        "hover:bg-[#25323D]",
        "p-16",
        "rounded-2xl",
        "flex",
        "gap-16",
        "cursor-pointer",
        "flex-col",
        "md:flex-row"
      )}
      onMouseDown={(event) => {
        if (event.button === 1) {
          window.open(fields.official_website, "_blank");
        }
      }}
      onClick={() => onProjectClick?.(slug)}
    >
      <div className="flex-shrink-0 mr-16 w-48 md:w-[72px] h-48 md:h-[72px]">
        <img
          src={fields.logo.replace("/raw/", "/logo/")}
          alt={`Logo for project ${fields.project_name}`}
          className="object-cover w-full h-full"
        />
      </div>
      <div className="flex-1 flex flex-col items-start">
        <h2 className="font-semibold text-16 text-white">{fields.project_name}</h2>
        <p className="mt-4 mb-8 text-14 text-[#c8c8c8] line-clamp-1">
          {fields.project_description}
        </p>
        <span className="text-14 font-medium">{fields.categories?.join(", ")}</span>
        <ExternalLinks fields={fields} />
      </div>
    </li>
  );
};

export default Project;

export const ExternalLinks = ({
  fields,
  className,
  iconClassNames,
}: {
  fields: ProjectAttributes;
  className?: string;
  iconClassNames?: string;
}) => {
  return (
    <div className={classNames("absolute top-12 right-12 flex items-center gap-18", className)}>
      {fields.defilama_link && (
        <a
          href={fields.defilama_link}
          className="flex"
          target="_blank"
          onClick={(e) => e.stopPropagation()}
        >
          <IconWithTooltip
            icon={<DefiLamaIcon />}
            tooltipContent="Visit Defi Llama"
            iconClassNames={iconClassNames}
          />
        </a>
      )}
      {fields.mobile_version && (
        <IconWithTooltip
          icon={<MobileLinkIcon />}
          tooltipContent="Mobile version"
          iconClassNames={iconClassNames}
        />
      )}
      {fields.audit1_link && (
        <a
          href={fields.audit1_link}
          className="flex"
          target="_blank"
          onClick={(e) => e.stopPropagation()}
        >
          <IconWithTooltip
            icon={<VerifiedIcon />}
            tooltipContent="Audit 1"
            iconClassNames={iconClassNames}
          />
        </a>
      )}
      {fields.audit2_link && (
        <a
          href={fields.audit2_link}
          className="flex"
          target="_blank"
          onClick={(e) => e.stopPropagation()}
        >
          <IconWithTooltip
            icon={<VerifiedIcon />}
            tooltipContent="Audit 2"
            iconClassNames={iconClassNames}
          />
        </a>
      )}
      {/* TODO: Use a boolean for live / coming soon, not a string */}
      {fields.live === "Coming soon" && (
        <span className="text-[10px] leading-[10px] px-6 py-3 text-white bg-[#555D64] rounded-full h-[20px] flex items-center justify-center">
          SOON
        </span>
      )}
    </div>
  );
};
