import { graphql } from "gatsby";
import React from "react";
import { I18nextContext, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import DAppStorePage from "../components/dapp-store/DAppStorePage";
import FooterSection from "../components/FooterSection";
import PageMeta from "../components/PageMeta";
import MilkHeader from "../components/MilkHeader";
import SubmitModal from "../components/dapp-store/SubmitModal";
import TermsOfServiceModal from "../components/dapp-store/TermsOfServiceModal";
import { externalLinks } from "../utils/constants";

const DappStore = () => {
  const { t } = useTranslation();
  const langContext = React.useContext(I18nextContext);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="relative">
      <PageMeta
        title={t("DApp Store • Milkomeda")}
        description={t(
          "The place to discover DApps and tools for the Milkomeda Cardano (C1) and Milkomeda Algorand (A1) ecosystem."
        )}
        locale={t("en_US")}
        language={langContext.language}
      />
      <TermsOfServiceModal />

      <MilkHeader />
      <section className="w-full pb-56 md:pb-88 relative z-20 bg-black section-gradient">
        <div className="absolute w-full h-full top-0 left-0 -z-10">
          <div className="absolute top-0 left-0 w-full h-full -z-20 bg-video-dapp-store">
            <video className="image-cover" playsInline autoPlay muted loop>
              <source src="/galaxy.webm" type="video/webm" />
              <source src="/galaxy.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
        <div className="pt-96 md:pt-120 container relative z-10">
          <div className="mx-auto max-w-[820px] text-center">
            <h1 className="heading-1 max-w-full font-semibold text-40 md:text-72">
              <Trans>Milkomeda DApp Store</Trans>
            </h1>
            <p className="mt-20 text-menu-white font-normal md:text-20 max-w-600 mx-auto">
              <Trans>
                The place to discover DApps and tools for the Milkomeda Cardano (C1) and Milkomeda
                Algorand (A1) ecosystem. Every DApp is reviewed before making it into this
                directory.
              </Trans>
            </p>
          </div>
          <div className="mt-24 md:mt-32 text-center flex flex-col">
            <div className="space-y-16 flex flex-col md:flex-row md:space-y-0 md:space-x-16 justify-center items-center">
              <SubmitModal />
              <a
                href="https://dcspark.github.io/milkomeda-documentation/cardano/for-developers/"
                target="_blank"
                className="btn-big inline-block max-sm:w-full font-bold px-32 py-13 rounded-md min-w-[218px]"
              >
                <Trans>Build on Milkomeda</Trans>
              </a>
            </div>
            <a
              href={externalLinks.addTokenBridge}
              className="underline hover:text-orange mt-24"
              target="_blank"
            >
              <Trans>Add Your Token to the Bridge</Trans>
            </a>
          </div>
        </div>
        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </section>
      <DAppStorePage />
      <FooterSection footerImage={"/images/footer-bg@2x.webp 2x, /images/footer-bg.webp 1x"} />
    </div>
  );
};

export default DappStore;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
