import React, { useMemo } from "react";
import Project from "./Project";
import { ProjectInterface } from "./DAppStorePage";
import classNames from "classnames";

interface ProjectListProps {
  projects: ProjectInterface[];
  onProjectClick?: (id: string) => void;
  category: string | null;
}

const ProjectList = ({ projects, onProjectClick, category }: ProjectListProps) => {
  return projects.length > 0 ? (
    <List projects={projects} onProjectClick={onProjectClick} category={category} />
  ) : (
    <div className="max-w-[200px] mx-auto pt-[56px] flex flex-col items-center">
      <div className="mb-[24px] w-[48px] h-[48px]">
        <img src="/images/icons/dapp-store/not-found.svg" alt="" />
      </div>
      <h2 className="font-bold text-20 leading-[28px]">No results found</h2>
      <p className="text-center mt-8 text-[#C8C8C8] text-14 leading-20">
        Try changing your search by changing categories.
      </p>
    </div>
  );
};

export default ProjectList;

const PAGE_SIZE = 14;

const PaginationNumber = ({
  isActive,
  number,
  onClick,
}: {
  isActive: boolean;
  number: number;
  onClick: () => void;
}) => (
  <button
    className={classNames(
      "flex items-center justify-center w-28 h-28 text-14 rounded cursor-pointer transition-colors active",
      isActive ? "bg-orange" : "bg-blue-filter hover:bg-blue-filter-hover"
    )}
    onClick={onClick}
  >
    <span className="sr-only">Go to {number} page</span>
    {number}
  </button>
);

const List = ({ projects, onProjectClick, category }: ProjectListProps) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const pageLimit = Math.ceil(projects.length / PAGE_SIZE);

  React.useEffect(() => {
    setCurrentPage(1);
  }, [category]);

  const currentProjectListData = React.useMemo(() => {
    const startIndex = currentPage * PAGE_SIZE - PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    return projects.slice(startIndex, endIndex);
  }, [currentPage, projects]);

  const getPaginationGroup = () => {
    const start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    return new Array(pageLimit).fill("").map((_, idx) => start + idx + 1);
  };

  return (
    <>
      {/* {getPaginationGroup().length > 1 && (
        <div className="flex gap-8 mb-16 justify-end">
          {getPaginationGroup().map((number) => (
            <PaginationNumber
              key={number}
              number={number}
              isActive={currentPage === number}
              onClick={() => setCurrentPage(number)}
            />
          ))}
        </div>
      )} */}
      <ul className="flex-1 flex flex-col gap-[12px]">
        {currentProjectListData.map((project, idx) => (
          <Project key={idx} {...project} onProjectClick={onProjectClick} />
        ))}
      </ul>
      {getPaginationGroup().length > 1 && (
        <div className="mt-40 flex gap-8 justify-center md:justify-end">
          {getPaginationGroup().map((number) => (
            <PaginationNumber
              key={number}
              number={number}
              isActive={currentPage === number}
              onClick={() => {
                setCurrentPage(number);
                const dappList = document.getElementById("dapp-list");
                let position = dappList?.getBoundingClientRect() ?? { top: 0, left: 0 };
                // scrolls to 100px above element
                window.scrollTo(position.left, position.top + window.scrollY - 100);
              }}
            />
          ))}
        </div>
      )}
    </>
  );
};
