import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { useQuery } from "@tanstack/react-query";

import { normalizeObjectKeys } from "../../utils/normalize";
import { filterProjects, generateSlug, getAllNetworks, groupByKey } from "../../utils/dapp";

import ProjectDetailsModal from "./ProjectDetailsModal";
import Filter from "./Filter";
import ProjectList from "./ProjectList";
import { Spinner } from "./SubmitModal";

// TODO: we should ensure type safety of the codebase <-> airtable so we don't have to make everything optional in the codebase
export interface ProjectAttributes {
  name: string;
  categories?: string[];
  audit1_link?: string;
  audit2_link?: string;
  defilama_link?: string;
  deploy: string;
  email: string;
  live: string;
  logo: string;
  mobile_version: string;
  presentation_screens: string;
  project_description: string;
  project_name?: string;
  official_website: string;
  show_on_website: string;
  compatible_liquid_staking: boolean;
}

export interface ProjectInterface {
  createdTime: string;
  fields: ProjectAttributes;
  id: string;
  //
  slug: string;
}

export type CategoryDetail = {
  id: string;
  name: string;
  type: string;
};
export type Categories = Record<string, CategoryDetail[]>;

const DEFAULT_NETWORK = "Cardano";

const queryKeys = {
  PROJECT: "project",
  CATEGORY: "category",
  NETWORK: "network",
};

const hashSeed = 1650956924; // random 32-bit number

/**
 * We use Fnv32a as a hash for the following reasons
 * 1) Small enough that you can easily inline it and easily compare to reference implementation it
 * (compared to xxhash, murmur, etc. which are quite lengthy)
 * 2) It has a better distribution than naive hashing (ex: Java's hashing of strings)
 * 3) For pure Javascript implementations, it's as fast as xxhash and murmur
 * See this post which shows Fnv32a does very well compared to alternatives
 * https://softwareengineering.stackexchange.com/a/145633
 */
function hashFnv32a(str: string, seed: number): number {
  let hval = seed === undefined ? 0x811c9dc5 : seed;

  for (let i = 0, l = str.length; i < l; i++) {
    hval ^= str.charCodeAt(i);
    hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
  }

  return hval >>> 0;
}

export const useDAppCategories = () => {
  const categoriesQuery = useQuery(["categories"], async () => {
    try {
      const response = await fetch(`/api/dapp-store/categories`);
      const data: any = await response.json();
      const categories = data?.records?.map((category: any) => ({
        id: category.id,
        name: category.fields.name,
        type: category.fields.type,
      }));
      return groupByKey(categories, "type");
    } catch (err) {
      console.error(err);
    }
  });

  return { ...categoriesQuery, categories: (categoriesQuery.data ?? {}) as Categories };
};

const useDAppProjects = () => {
  const projectsQuery = useQuery(["projects"], async () => {
    try {
      const response = await fetch(`/api/dapp-store/projects`);
      const data: any = await response.json();
      const normalizedData = normalizeObjectKeys(data.records);
      return (
        normalizedData
          // TODO: this is nlog(n) calculations of the hash function, but it's fast enough in practice I think
          .sort(
            (p1: any, p2: any) =>
              hashFnv32a(p1.fields.project_name, hashSeed) -
              hashFnv32a(p2.fields.project_name, hashSeed)
          )
          .map((project: any) => ({
            ...project,
            slug: generateSlug(project.fields.project_name ?? ""),
          }))
      );
    } catch (err) {
      console.error(err);
    }
  });
  return { ...projectsQuery, dappProjects: projectsQuery.data ?? [] };
};

const DAppStorePage = () => {
  const queryParams = new URLSearchParams(
    typeof window !== "undefined" ? window.location.search : ""
  );

  const queryProjectSlug = queryParams.get(queryKeys.PROJECT);
  const queryCategory = queryParams.get(queryKeys.CATEGORY);
  const queryNetwork = queryParams.get(queryKeys.NETWORK) ?? DEFAULT_NETWORK;

  const {
    dappProjects,
    isLoading: isProjectsLoading,
    isSuccess: isProjectsSuccess,
    isError: isProjectsError,
  } = useDAppProjects();
  const [selectedProject, setSelectedProject] = useState<ProjectInterface | null>(null);

  const {
    categories,
    isLoading: isCategoriesLoading,
    isSuccess: isCategoriesSuccess,
    isError: isCategoriesError,
  } = useDAppCategories();
  const [currentCategory, setCurrentCategory] = useState<string | null>(null);
  const [currentNetwork, setCurrentNetwork] = useState<string | null>(null);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredProjects = filterProjects({
    projects: dappProjects,
    network: currentNetwork,
    category: currentCategory,
    search: searchQuery,
  });

  useEffect(() => {
    if (!dappProjects) return;

    if (queryCategory || queryNetwork || queryProjectSlug) {
      // if (queryCategory || queryProjectSlug) {
      setCurrentNetwork(queryNetwork);
      setCurrentCategory(queryCategory);
      const project = dappProjects.find(
        (project: ProjectInterface) => project.slug === queryProjectSlug
      );
      setSelectedProject(project);
      setDetailModalOpen(true);
    }
    // }, [queryCategory, dappProjects, queryProjectSlug]);
  }, [queryCategory, queryNetwork, dappProjects, queryProjectSlug]);

  const handleProjectClick = (slug: string) => {
    const matchingProject = filteredProjects?.find((project) => project.slug === slug);

    if (matchingProject) {
      setSelectedProject(matchingProject);
      queryParams.set(queryKeys.PROJECT, matchingProject?.slug);
      navigate(`?${queryParams.toString()}`);
      setDetailModalOpen(true);
    }
  };

  const handleSearchQuery = React.useCallback(
    (search: string) => {
      setSearchQuery(search);
    },
    [searchQuery, setSearchQuery]
  );

  const renderProjectsAndCategoriesList = () => {
    const isLoading = isCategoriesLoading || isProjectsLoading;
    const isSuccess = isCategoriesSuccess || isProjectsSuccess;
    const isError = isCategoriesError || isProjectsError;
    if (isLoading) {
      return (
        <div className="w-full flex items-center justify-center p-24 md:p-40">
          <Spinner className="w-40 h-40" />
        </div>
      );
    }
    if (isSuccess) {
      return (
        <>
          <div className="md:basis-1/4 md:max-w-[25%] md:px-12">
            <Filter
              currentNetwork={currentNetwork}
              currentCategory={currentCategory}
              networks={getAllNetworks(dappProjects)}
              categories={categories}
              searchQuery={searchQuery}
              handleSearchQuery={handleSearchQuery}
              onNetworkClick={(network) => {
                setCurrentNetwork(network);
                setCurrentCategory(null);
                queryParams.set(queryKeys.NETWORK, network);
                queryParams.delete(queryKeys.CATEGORY);
                navigate(`?${queryParams.toString()}`);
              }}
              onCategoryClick={(category) => {
                // All categories
                if (category === "") {
                  setCurrentCategory(null);
                  queryParams.delete(queryKeys.CATEGORY);
                  navigate(`?${queryParams.toString()}`);
                  return;
                }
                setCurrentCategory(category);
                queryParams.set(queryKeys.CATEGORY, category);
                navigate(`?${queryParams.toString()}`);
              }}
            />
          </div>
          <div className="md:basis-3/4 md:max-w-[75%] md:px-12">
            <ProjectList
              category={currentCategory}
              projects={filteredProjects}
              onProjectClick={handleProjectClick}
            />
          </div>
        </>
      );
    }

    if (isError) {
      return (
        <div className="text-white w-full flex items-center justify-center p-24 md:p-40">
          Something went wrong. Try again
        </div>
      );
    }
    return null;
  };

  return (
    <div id="dapp-list" className="bg-blue-bg-dark pt-24 pb-[80px]">
      <div className="relative md:px-2 z-20 container text-white flex flex-col md:flex-row">
        {renderProjectsAndCategoriesList()}
        <ProjectDetailsModal
          isOpen={detailModalOpen}
          onClose={() => {
            setDetailModalOpen(false);
            queryParams.delete(queryKeys.PROJECT);
            navigate(`?${queryParams.toString()}`);
          }}
          selectedProject={selectedProject}
          onProjectChange={(project) => {
            setSelectedProject(project);
            queryParams.set(queryKeys.PROJECT, project.slug);
            navigate(`?${queryParams.toString()}`);
          }}
          projectList={filteredProjects}
        />
      </div>
      <div className="container h-full absolute top-0 left-0 right-0 bottom-0 z-10">
        <div className="layout-grid">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default DAppStorePage;
