import React, { useRef } from "react";
import { ProjectInterface } from "./DAppStorePage";
import ArrowRightIcon from "../../../static/images/icons/dapp-store/arrow-right.inline.svg";
import ArrowLeftIcon from "../../../static/images/icons/dapp-store/arrow-left.inline.svg";
import ExternalLinkIcon from "../../../static/images/icons/dapp-store/external-link.inline.svg";
import classNames from "classnames";
import BaseModal from "./BaseModal";

import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Mousewheel, Navigation, Pagination } from "swiper";

import { Swiper as SwiperCore } from "swiper/types";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/a11y";
import "swiper/css/mousewheel";
import { ExternalLinks } from "./Project";

const ProjectDetailsModal = ({
  isOpen,
  onClose,
  selectedProject,
  onProjectChange,
  projectList,
}: {
  isOpen: boolean;
  onClose: () => void;
  selectedProject: ProjectInterface | null;
  onProjectChange: (key: ProjectInterface) => void;
  projectList: ProjectInterface[];
}): React.ReactElement | null => {
  const swiperRef = useRef<SwiperCore>();

  if (!selectedProject || !projectList) {
    return null;
  }

  const currentProjectPosition = projectList?.indexOf(selectedProject);
  const previousProject =
    projectList[currentProjectPosition - 1] ?? projectList[projectList.length - 1];
  const nextProject = projectList[currentProjectPosition + 1] ?? projectList[0];

  const handlePreviousProjectClick = () => {
    onProjectChange(previousProject);
  };

  const handleNextProjectClick = () => {
    onProjectChange(nextProject);
  };
  const presentationScreens = selectedProject?.fields?.presentation_screens
    ?.split("; ")
    .map((item) => item.trim())
    .filter(Boolean)
    .map((item) => item.replace("/raw/", "/presentation-screen/"));

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      isDetailsModal={projectList.length > 0}
      overlayComponent={
        projectList.length > 1 && (
          <>
            <button
              type="button"
              className={classNames(
                "flex items-center md:flex-col lg:flex-row absolute md:top-1/2 md:-translate-y-1/2 md:left-24 opacity-60 transition-opacity hover:opacity-100",
                "max-sm:bottom-0 left-0 max-sm:w-1/2 max-sm:bg-black max-sm:opacity-100 max-sm:h-[80px] max-sm:justify-start"
                // !previousProject && "hidden"
              )}
              disabled={!previousProject}
              onClick={handlePreviousProjectClick}
            >
              <div className="w-24 md:w-48 h-24 md:h-48 flex-center max-sm:mr-4">
                <ArrowLeftIcon />
              </div>
              <div className="max-md:flex max-md:items-end max-md:gap-8 max-md:relative text-left">
                <p className="max-md:hidden text-10 text-disabled uppercase font-semibold">
                  PREVIOUS
                </p>
                <div className="flex-shrink-0 lg:mt-4 w-40 h-40 rounded-full overflow-hidden">
                  <img
                    src={previousProject?.fields?.logo}
                    className="w-full h-full object-cover bg-blue-bg-dark"
                    alt=""
                  />
                </div>
                <div className="lg:max-w-85 mt-4">
                  <p className="lg:hidden text-10 text-disabled uppercase font-semibold">
                    PREVIOUS
                  </p>
                  <p className="text-14 text-white">{previousProject?.fields?.project_name}</p>
                </div>
              </div>
            </button>
            <button
              type="button"
              className={classNames(
                "flex items-center md:flex-col-reverse lg:flex-row absolute md:top-1/2 md:-translate-y-1/2 md:right-24 cursor-pointer md:opacity-60 transition-opacity hover:opacity-100 disabled:hidden",
                "max-sm:bottom-0 right-0 max-sm:w-1/2 max-sm:bg-black max-sm:opacity-100 max-sm:h-[80px] max-sm:justify-end"
                // !nextProject && "hidden"
              )}
              disabled={!nextProject}
              onClick={handleNextProjectClick}
            >
              <div className="flex lg:flex-col max-md:gap-8 items-end max-md:relative text-right">
                <p className="max-md:hidden text-10 text-disabled font-semibold">NEXT</p>
                <div className="flex-shrink-0 lg:mt-4 w-40 h-40 rounded-full max-md:order-1 overflow-hidden">
                  <img
                    src={nextProject?.fields?.logo}
                    alt=""
                    className="w-full h-full object-cover bg-blue-bg-dark"
                  />
                </div>
                <div className="lg:max-w-85 mt-4">
                  <p className="lg:hidden text-10 text-disabled uppercase font-semibold">Next</p>
                  <p className="text-14 text-white" data-project-next>
                    {nextProject?.fields?.project_name}
                  </p>
                </div>
              </div>
              <div className="w-24 md:w-48 h-24 md:h-48 flex-center max-sm:ml-4">
                <ArrowRightIcon />
              </div>
            </button>
          </>
        )
      }
    >
      <div className="flex flex-col h-full">
        {presentationScreens?.length > 1 ? (
          <Swiper
            slidesPerView={1}
            pagination={{ clickable: true }}
            modules={[A11y, Pagination, Navigation, Mousewheel]}
            mousewheel
            className="w-full flex-shrink-0"
            loop
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
          >
            {presentationScreens.map((image, idx) => (
              <SwiperSlide className="relative" key={idx}>
                <div className="aspect-video max-h-[340px] mt-6">
                  <img className="w-full h-full object-cover bg-blue-bg-dark" src={image} alt="" />
                </div>
                <button
                  onClick={() => swiperRef.current?.slidePrev()}
                  className="slider-arrow slider-arrow--left"
                >
                  <ArrowLeftIcon />
                </button>
                <button
                  onClick={() => swiperRef.current?.slideNext()}
                  className="slider-arrow slider-arrow--right"
                >
                  <ArrowRightIcon />
                </button>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <div className="aspect-video max-h-[340px] mt-6 flex-shrink-0">
            <img
              className="w-full h-full object-cover bg-blue-bg-dark"
              src={selectedProject?.fields?.presentation_screens?.split("; ")?.[0]}
              alt=""
            />
          </div>
        )}

        <div
          className={classNames(
            "relative flex-1 basis-full overflow-auto",
            projectList.length > 1 && "max-sm:pb-[80px]"
          )}
        >
          <ExternalLinks
            fields={selectedProject?.fields}
            className="top-24 right-24"
            iconClassNames="w-[22px] h-[22px]"
          />
          <div className="flex flex-col lg:flex-row p-14 lg:p-24 pb-48 bg-blue-filter">
            <div className="mr-24 w-[72px] h-[72px] md:w-[90px] md:h-[90px] lg:w-128 lg:h-128 flex-shrink-0">
              <img
                src={selectedProject?.fields?.logo.replace("/raw/", "/logo/")}
                alt=""
                className="w-full h-full object-cover"
              />
            </div>
            <div className="mt-16 md:mt-0">
              <p className="text-14">{selectedProject?.fields?.categories?.join(", ")}</p>
              <h2 className="mt-8 text-28 font-semibold">{selectedProject?.fields.project_name}</h2>
              <p className="mt-8 text-disabled max-sm:text-14">
                {selectedProject?.fields.project_description}
              </p>
              <div className="mt-24">
                <a
                  href={selectedProject?.fields?.official_website}
                  target="_blank"
                  className="outlined-button"
                >
                  Visit {selectedProject?.fields.project_name} <ExternalLinkIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};
export default ProjectDetailsModal;
