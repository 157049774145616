import React from "react";
import classNames from "classnames";
import { useState } from "react";
import { Upload } from "upload-js";
import { useForm, SubmitHandler } from "react-hook-form";

import BaseModal from "./BaseModal";
import { Trans } from "react-i18next";
import VerifiedIcon from "../../../static/images/icons/dapp-store/verified.inline.svg";
import MobileLinkIcon from "../../../static/images/icons/dapp-store/mobile-link.inline.svg";
import DragDropIcon from "../../../static/images/icons/dapp-store/drag-drop.inline.svg";
import DefiLamaIcon from "../../../static/images/icons/dapp-store/defilama-link.inline.svg";

import { Link } from "gatsby";
import { useDropzone } from "react-dropzone";
import { useDAppCategories } from "./DAppStorePage";
import { externalLinks } from "../../utils/constants";

type PresentationImage = File & { preview: string };
type IFormInputs = {
  name: string;
  "contact-details": string;
  deploy: string;
  live: string;
  "project-name": string;
  "project-description": string;
  "official-website": string;
  categories: string[];
  "defilama-link": string;
  "audit1-link": string;
  "audit2-link": string;
  "mobile-version": string;
  logo: PresentationImage | null;
  "presentation-screens": PresentationImage[];
};

const MAX_LOGO_IMG_SIZE = 10000000; // 10mb
const MIN_WIDTH_LOGO_IMG = 80;
const MIN_HEIGHT_LOGO_IMG = 80;
const MAX_PRESENTATION_IMG_SIZE = 10000000; // 10mb
const MIN_WIDTH_PRESENTATION_IMG = 1200;
const MIN_HEIGHT_PRESENTATION_IMG = 676;
const errorImageMessage = "Image does meet the minimum requirements.";

const SubmitModal = () => {
  const upload = Upload({ apiKey: process.env.GATSBY_UPLOAD_API_KEY ?? "free" });
  const { isSuccess: isCategoryListSuccess, categories: categoryList } = useDAppCategories();

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors },
    reset: resetForm,
  } = useForm<IFormInputs>({
    defaultValues: {
      logo: null,
      "presentation-screens": [],
    },
  });

  const [presentationFiles, setPresentationFiles] = useState<PresentationImage[]>([]);
  const [presentationFileError, setPresentationFileError] = useState(false);

  const [logoFileError, setLogoFileError] = useState(false);

  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [status, setStatus] = React.useState<"idle" | "pending" | "success" | "rejected">("idle");

  const isLoading = status === "pending";
  const isSuccess = status === "success";
  const isError = status === "rejected";

  const logoFile = getValues("logo");

  const {
    open: openLogoFile,
    getRootProps: getRootLogoProps,
    getInputProps: getInputLogoProps,
  } = useDropzone({
    multiple: false,
    maxSize: MAX_LOGO_IMG_SIZE,
    accept: {
      "image/jpg": [],
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop: (acceptedFiles) => {
      const logo = acceptedFiles[0];
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = (e: any) => {
        const binaryUrl = e.target.result;
        const image = new Image();
        image.addEventListener("load", function () {
          if (
            logo.size > MAX_LOGO_IMG_SIZE ||
            image.width < MIN_WIDTH_LOGO_IMG ||
            image.height < MIN_HEIGHT_LOGO_IMG
          ) {
            setLogoFileError(true);
          } else {
            const imageInfo = Object.assign(logo, {
              preview: URL.createObjectURL(logo),
            });
            setValue("logo", imageInfo, { shouldValidate: true });
          }
        });
        image.src = binaryUrl;
      };
      reader.readAsDataURL(logo);
    },
  });

  const {
    open: openPresentationFile,
    getRootProps: getRootPresentationProps,
    getInputProps: getInputPresentationProps,
  } = useDropzone({
    maxFiles: 10,
    maxSize: MAX_PRESENTATION_IMG_SIZE,
    accept: {
      "image/jpg": [],
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((acceptedImg) => {
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = (e: any) => {
          const binaryUrl = e.target.result;
          const image = new Image();
          image.addEventListener("load", function () {
            if (
              acceptedImg.size > MAX_PRESENTATION_IMG_SIZE ||
              image.width < MIN_WIDTH_PRESENTATION_IMG ||
              image.height < MIN_HEIGHT_PRESENTATION_IMG
            ) {
              setPresentationFileError(true);
            } else {
              const imageInfo = Object.assign(acceptedImg, {
                preview: URL.createObjectURL(acceptedImg),
              });
              setValue("presentation-screens", [...presentationFiles, imageInfo], {
                shouldValidate: true,
              });

              setPresentationFiles((prev) => {
                return [...prev, imageInfo];
              });
            }
          });
          image.src = binaryUrl;
        };
        reader.readAsDataURL(acceptedImg);
      });
    },
  });

  const removeFile = (file: PresentationImage) => () => {
    const newFiles = [...presentationFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setPresentationFiles(newFiles);
    setValue("presentation-screens", newFiles);
  };

  const presentationFilePreview = presentationFiles?.map((file) => (
    <li className="flex gap-16" key={file.name}>
      <div className="w-156 h-88 flex-shrink-0 rounded overflow-hidden">
        <img
          src={file.preview}
          className="object-cover w-full h-full"
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
          alt=""
        />
      </div>
      <div className="flex-1">
        <div className="flex justify-between gap-16 text-14 text-menu-white">
          <p>{file.name}</p>
          <span>100%</span>
        </div>
        <div className="mt-16 w-full h-4 rounded-3xl bg-input-border overflow-hidden">
          <div className="h-full bg-orange"></div>
        </div>
        <div className="mt-16 text-14 text-orange cursor-pointer" onClick={removeFile(file)}>
          Delete
        </div>
      </div>
    </li>
  ));

  const handleProjectFormSubmit: SubmitHandler<IFormInputs> = async (formData) => {
    setWasSubmitted(true);
    setStatus("pending");
    try {
      //@ts-ignore
      const { fileUrl: logoFileUrl } = await upload.uploadFile(logoFile);

      const gallery = [];
      for (let presentImgFile of presentationFiles) {
        const { fileUrl } = await upload.uploadFile(presentImgFile);
        gallery.push(fileUrl);
      }

      const formValues = {
        ...formData,
        logo: logoFileUrl,
        "presentation-screens": gallery.join("; "),
        "show-on-website": false,
      };

      const response = await fetch(`/api/dapp-store/projects`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          fields: formValues,
        }),
      });
      const data: any = await response.json();
      if (data?.createdTime) {
        setStatus("success");
      }
    } catch (error) {
      console.log(error);
      setStatus("rejected");
    }
  };

  React.useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => {
      logoFile && URL.revokeObjectURL(logoFile.preview);
      presentationFiles.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, []);

  const reset = () => {
    resetForm();
    setPresentationFiles([]);
    setValue("presentation-screens", []);
    setPresentationFileError(false);
    setValue("logo", null);
    setLogoFileError(false);
    setWasSubmitted(false);
    setStatus("idle");
  };

  return (
    <>
      <button
        onClick={() => {
          setSubmitModalOpen(true);
        }}
        className="inline-block max-sm:w-full font-bold px-32 py-13 border border-white rounded-[8px] transition-colors hover:bg-white hover:text-black min-w-[218px]"
      >
        <Trans>Submit your project</Trans>
      </button>
      <BaseModal
        isOpen={submitModalOpen}
        onClose={() => {
          setSubmitModalOpen(false);
          reset();
        }}
        isLoading={isLoading}
        isSubmitted={wasSubmitted}
      >
        {!wasSubmitted && (
          <div className="flex flex-col h-full">
            <div className="text-center">
              <h2 className="font-semibold text-32">Submit your project</h2>
              <p className="mt-16 text-menu-white">
                We will only accept complete submissions. Milkomeda DApp Store keeps the right to
                exclude any project from the Ecosystem page.
              </p>
            </div>
            <form
              onSubmit={handleSubmit(handleProjectFormSubmit)}
              className="my-32 w-full flex-auto overflow-y-auto"
            >
              {/* <!-- 1. Your name and email address: --> */}
              <div className="flex">
                <div className="text-20 mr-5 md:mr-24 w-18 font-bold text-menu-white">1.</div>
                <div className="w-full md:pr-40">
                  <h3 className="text-18 pt-4 font-semibold mb-25">Your name *</h3>
                  <div className="max-sm:-ml-22">
                    <div>
                      <label htmlFor="name" className="sr-only">
                        Your Name
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="form-input"
                        placeholder="Your name"
                        {...register("name", { required: "Name is required" })}
                      />
                    </div>
                    <ErrorMessage message={errors?.name?.message} />
                  </div>
                </div>
              </div>
              <div className="mt-25 md:mt-32 flex">
                <div className="text-20 mr-5 md:mr-24 w-18 font-bold text-menu-white">2.</div>
                <div className="w-full md:pr-40">
                  <h3 className="text-18 pt-4 font-semibold mb-25">
                    Contact details *
                    <span className="font-normal text-menu-white text-14 whitespace-nowrap">
                      (e-mail/telegram/discord/twitter)
                    </span>
                  </h3>
                  <div className="max-sm:-ml-22">
                    <label htmlFor="contact-details" className="sr-only">
                      Contact Details
                    </label>
                    <input
                      id="contact-details"
                      type="text"
                      className="form-input"
                      placeholder="example@example.com"
                      {...register("contact-details", { required: "Contact details is required" })}
                    />
                  </div>
                  <ErrorMessage message={errors?.["contact-details"]?.message} />
                </div>
              </div>

              {/* <!-- 2. Where is your project deployed: --> */}
              <div className="mt-25 md:mt-32 flex">
                <div className="text-20 mr-5 md:mr-24 w-18 font-bold text-menu-white">3.</div>
                <div className="w-full md:pr-40">
                  <h3 className="text-18 pt-4 font-semibold mb-25">
                    Where is your project deployed: *
                  </h3>
                  <div className="max-sm:-ml-24">
                    <ul className="grid gap-16">
                      <li>
                        <label
                          htmlFor="milkomeda-c1"
                          className="flex items-center w-full cursor-pointer"
                        >
                          <input
                            id="milkomeda-c1"
                            type="radio"
                            className="form-checkbox form-checkbox--circle"
                            value="Cardano"
                            {...register("deploy", { required: "Ecosystem is required" })}
                          />
                          <p className="ml-16 w-full">Milkomeda C1</p>
                        </label>
                      </li>
                      <li>
                        <label
                          htmlFor="milkomeda-a1"
                          className="flex items-center w-full cursor-pointer"
                        >
                          <input
                            id="milkomeda-a1"
                            type="radio"
                            className="form-checkbox form-checkbox--circle"
                            value="Algorand"
                            {...register("deploy", { required: "Ecosystem is required" })}
                          />
                          <p className="ml-16 w-full">Milkomeda A1</p>
                        </label>
                      </li>
                    </ul>
                    <ErrorMessage className="mt-8" message={errors?.live?.message} />
                  </div>
                </div>
              </div>
              {/* <!-- 3. Is your project live on Milkomeda or coming soon? --> */}
              <div className="mt-25 md:mt-32 flex">
                <div className="text-20 mr-5 md:mr-24 w-18 font-bold text-menu-white">4.</div>
                <div className="w-full md:pr-40">
                  <h3 className="text-18 pt-4 font-semibold mb-25">
                    Is your project live on Milkomeda or coming soon? *
                  </h3>
                  <div className="max-sm:-ml-24">
                    <ul className="grid gap-16">
                      <li>
                        <label htmlFor="live" className="flex items-center w-full cursor-pointer">
                          <input
                            id="live"
                            type="radio"
                            className="form-checkbox form-checkbox--circle"
                            value="Yes, it’s live"
                            {...register("live", { required: "Project status is required" })}
                          />
                          <p className="ml-16 w-full">Yes, it’s live</p>
                        </label>
                      </li>
                      <li>
                        <label
                          htmlFor="coming-soon"
                          className="flex items-center w-full cursor-pointer"
                        >
                          <input
                            id="coming-soon"
                            type="radio"
                            className="form-checkbox form-checkbox--circle"
                            value="Coming soon"
                            {...register("live", { required: "Project status is required" })}
                          />
                          <p className="ml-16 w-full">Coming soon</p>
                        </label>
                      </li>
                    </ul>
                    <ErrorMessage className="mt-8" message={errors?.live?.message} />
                  </div>
                  <p className="text-14 text-menu-white mt-10">
                    * If your DApp requires a token to be added to Milkomeda Bridge, please send
                    your request to the Milkomeda Team using{" "}
                    <a className="underline" target="_blank" href={externalLinks.addTokenBridge}>
                      this form
                    </a>
                    .
                  </p>
                </div>
              </div>
              {/* <!-- 4. What is your project name --> */}
              <div className="mt-25 md:mt-32 flex">
                <div className="text-20 mr-5 md:mr-24 w-18 font-bold text-menu-white">5.</div>
                <div className="w-full md:pr-40">
                  <h3 className="text-18 pt-4 font-semibold mb-25">What is your project name? *</h3>
                  <div className="max-sm:-ml-22">
                    <label htmlFor="project-name" className="sr-only">
                      Project name...
                    </label>
                    <input
                      id="project-name"
                      type="text"
                      className="form-input"
                      placeholder="Project name..."
                      {...register("project-name", { required: "Project name is required" })}
                    />
                  </div>
                  <ErrorMessage message={errors?.["project-name"]?.message} />
                </div>
              </div>
              {/* <!-- 5. What is your project about? --> */}
              <div className="mt-25 md:mt-32 flex">
                <div className="text-20 mr-5 md:mr-24 w-18 font-bold text-menu-white">6.</div>
                <div className="w-full md:pr-40">
                  <div className="mb-25 flex items-center justify-between">
                    <h3 className="text-18 pt-4 font-semibold">What is your project about? *</h3>
                    <div id="char_count" className="ml-16 text-14 text-menu-white">
                      0 / 600
                    </div>
                  </div>
                  <div className="max-sm:-ml-22">
                    <textarea
                      id="project-desc"
                      className="form-textarea"
                      cols={30}
                      rows={3}
                      placeholder="Describe your project..."
                      maxLength={600}
                      {...register("project-description", {
                        required: "Project description is required",
                      })}
                    />
                  </div>
                  <ErrorMessage message={errors?.["project-description"]?.message} />
                </div>
              </div>
              {/* <!-- 6. Upload your logo and presentation screens. --> */}
              <div className="mt-25 md:mt-32 flex">
                <div className="text-20 mr-5 md:mr-24 w-18 font-bold text-menu-white">7.</div>
                <div className="w-full md:pr-40">
                  <h3 className="text-18 pt-4 font-semibold mb-25">
                    Upload your logo and presentation screens. *
                  </h3>
                  <div className="max-sm:-ml-22">
                    <p className="mb-16 block text-14 uppercase font-semibold">LOGO</p>
                    <div className="flex gap-16 max-sm:flex-col">
                      <div
                        {...getRootLogoProps({
                          className: classNames(
                            "dropzone relative group w-88 h-88 flex-shrink-0 flex items-center justify-center border-2 border-menu-white transition-colors hover:border-white border-dashed rounded cursor-pointer",
                            logoFile && "border-0"
                          ),
                        })}
                      >
                        <DragDropIcon className="fill-menu-white transition-colors group-hover:fill-white" />
                        <input
                          {...register("logo", {
                            validate: () => (getValues("logo") != null ? true : "Logo is required"),
                          })}
                          {...getInputLogoProps({
                            onChange: register("logo").onChange,
                          })}
                        />
                        <div>
                          {logoFile && (
                            <img
                              src={logoFile.preview}
                              alt=""
                              className="absolute top-0 left-0 w-full h-full object-cover"
                            />
                          )}
                        </div>
                      </div>
                      <div>
                        <p className="text-menu-white text-14">
                          Square shape PNG/JPG only, at least 80 x 80px.
                          <br />
                          Size limit 10MB.
                        </p>
                        {logoFileError && (
                          <p className="text-orange text-14 font-bold my-4">{logoFileError}</p>
                        )}
                        <div className="flex items-center mt-8">
                          <button
                            type="button"
                            onClick={() => {
                              openLogoFile();
                              setLogoFileError(false);
                            }}
                            className="inline-block px-24 py-7 text-14 font-semibold border rounded-full cursor-pointer transition-colors hover:bg-white hover:text-black"
                          >
                            Choose file
                          </button>
                          {logoFile != null && (
                            <button
                              className={classNames(
                                "text-14 text-orange px-16 cursor-pointer",
                                logoFile ? "block" : "hidden"
                              )}
                              onClick={() => {
                                setValue("logo", null, { shouldValidate: true });
                              }}
                            >
                              Delete
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <ErrorMessage className="mt-8" message={errors?.logo?.message} />
                    <div className="mt-32">
                      <p className="mb-16 block text-14 uppercase font-semibold">
                        Presentation screens (min 1 required, Up to 10 images)
                      </p>
                      <div>
                        <div className="flex max-sm:flex-col gap-16">
                          <div
                            {...getRootPresentationProps({
                              className:
                                "dropzone group relative w-156 h-88 flex-shrink-0 flex items-center justify-center border-2 border-menu-white transition-colors hover:border-white border-dashed rounded cursor-pointer",
                            })}
                          >
                            <DragDropIcon className="fill-menu-white transition-colors group-hover:fill-white" />
                            <input
                              {...register("presentation-screens", {
                                validate: () =>
                                  getValues("presentation-screens")?.length !== 0
                                    ? true
                                    : "Presentation screens are required",
                              })}
                              {...getInputPresentationProps({
                                onChange: register("presentation-screens").onChange,
                              })}
                            />
                          </div>
                          <div>
                            <p className="text-menu-white text-14">
                              PNG/JPG only, at least 1200 x 676px (16:9 ratio). Size limit 10MB
                            </p>
                            {presentationFileError && (
                              <p className="text-orange text-14 font-bold my-4">
                                {errorImageMessage}
                              </p>
                            )}
                            <div className="flex items-center mt-8">
                              <button
                                type="button"
                                onClick={() => {
                                  openPresentationFile();
                                  setPresentationFileError(false);
                                }}
                                className="inline-block px-24 py-7 text-14 font-semibold border rounded-full cursor-pointer transition-colors hover:bg-white hover:text-black"
                              >
                                Choose file
                              </button>
                              {presentationFilePreview.length > 0 && (
                                <button
                                  className="text-14 text-orange px-16 cursor-pointer"
                                  type="button"
                                  onClick={() => {
                                    setPresentationFiles([]);
                                    setValue("presentation-screens", []);
                                    setPresentationFileError(false);
                                  }}
                                >
                                  Remove all
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          className="mt-8"
                          message={errors?.["presentation-screens"]?.message}
                        />

                        {presentationFilePreview.length > 0 && (
                          <ul className="mt-16 w-full flex flex-col gap-16">
                            {presentationFilePreview}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- 7. What is your project's official website? --> */}
              <div className="mt-25 md:mt-32 flex">
                <div className="text-20 mr-5 md:mr-24 w-18 font-bold text-menu-white">8.</div>
                <div className="w-full md:pr-40">
                  <h3 className="text-18 pt-4 font-semibold mb-25">
                    What is your project's official website? *
                  </h3>
                  <div className="max-sm:-ml-22">
                    <label htmlFor="official-website" className="sr-only">
                      What is your project's official website?
                    </label>
                    <input
                      id="official-website"
                      type="text"
                      className="form-input"
                      placeholder="http://"
                      {...register("official-website", {
                        required: `Project's official website is required`,
                      })}
                    />
                  </div>
                  <ErrorMessage message={errors?.["official-website"]?.message} />
                </div>
              </div>
              {/* <!-- 8. Which categories best define your dApp? --> */}
              <div className="mt-25 md:mt-32 flex">
                <div className="text-20 mr-5 md:mr-24 w-18 font-bold text-menu-white">9.</div>
                <div className="w-full md:pr-40">
                  <h3 className="text-18 pt-4 font-semibold mb-25">
                    Which categories best define your dApp? *
                  </h3>
                  <div className="max-sm:-ml-24 grid grid-cols-2 justify-between gap-16">
                    {isCategoryListSuccess &&
                      Object.entries(categoryList)?.map(([titleCategory, list]) => (
                        <ul className="grid gap-16 self-start" key={titleCategory}>
                          {list?.map((category) => (
                            <li key={category.id}>
                              <label
                                htmlFor={`category-${category.name}`}
                                className="flex items-center w-full cursor-pointer"
                              >
                                <input
                                  id={`category-${category.name}`}
                                  type="checkbox"
                                  className="form-checkbox form-checkbox--square"
                                  {...register(`categories`, {
                                    validate: () =>
                                      getValues("categories").length
                                        ? true
                                        : "Select at least one category",
                                  })}
                                  value={category.name as any}
                                />
                                <p className="ml-16 w-full">{category.name}</p>
                              </label>
                            </li>
                          ))}
                        </ul>
                      ))}
                  </div>
                  <ErrorMessage className="mt-12" message={errors?.categories?.message} />
                </div>
              </div>
              {/* <!-- 9. Additional information --> */}
              <div className="mt-25 md:mt-32 flex">
                <div className="text-20 mr-5 md:mr-24 w-18 font-bold text-menu-white">10.</div>
                <div className="w-full md:pr-40">
                  <h3 className="text-18 pt-4 font-semibold mb-25">Additional information</h3>
                  <div className="max-sm:-ml-22">
                    <div>
                      <label htmlFor="defilama-link" className="text-14 text-menu-white mb-4">
                        Defillama link
                      </label>
                      <div className="flex border-2 border-input-border rounded">
                        <div className="w-48 h-48 p-12 flex items-center justify-center border-r-2 border-input-border">
                          <DefiLamaIcon className="w-full h-full" />
                        </div>
                        <input
                          id="defilama-link"
                          type="text"
                          className="form-input border-0"
                          placeholder="http://"
                          {...register("defilama-link")}
                        />
                      </div>
                    </div>
                    <div className="mt-16">
                      <label htmlFor="audit-1" className="text-14 text-menu-white mb-4">
                        Audit 1
                      </label>
                      <div className="flex border-2 border-input-border rounded">
                        <div className="w-48 h-48 p-12 flex items-center justify-center border-r-2 border-input-border">
                          <VerifiedIcon className="w-full h-full fill-white" />
                        </div>
                        <input
                          id="audit-1"
                          type="text"
                          className="form-input border-0"
                          placeholder="http://"
                          {...register("audit1-link")}
                        />
                      </div>
                    </div>
                    <div className="mt-16">
                      <label htmlFor="audit-2" className="text-14 text-menu-white mb-4">
                        Audit 2
                      </label>
                      <div className="flex border-2 border-input-border rounded">
                        <div className="w-48 h-48 p-12 flex items-center justify-center border-r-2 border-input-border">
                          <VerifiedIcon className="w-full h-full fill-white" />
                        </div>
                        <input
                          id="audit-2"
                          type="text"
                          className="form-input border-0"
                          placeholder="http://"
                          {...register("audit2-link")}
                        />
                      </div>
                    </div>
                    <div className="mt-16">
                      <div className="text-14 text-menu-white mb-4">
                        Do you offer mobile version?
                      </div>
                      <div className="flex border-2 border-input-border rounded">
                        <div className="flex-shrink-0 w-48 h-48 p-12 flex items-center justify-center border-r-2 border-input-border">
                          <MobileLinkIcon className="w-full h-full fill-white" />
                        </div>
                        <label
                          htmlFor="mobile-version"
                          className="flex items-center py-12 px-16 w-full cursor-pointer"
                        >
                          <input
                            id="mobile-version"
                            type="checkbox"
                            className="form-checkbox form-checkbox--square"
                            {...register("mobile-version")}
                          />
                          <p className="ml-16 w-full">Yes, mobile version available</p>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:px-40 mt-48">
                <button
                  className="absolute left-40 right-40 bottom-[25px] md:bottom-30 p-13 bg-orange text-center rounded-full disabled:bg-orange-light disabled:opacity-70"
                  type="submit"
                >
                  Submit my project
                </button>
              </div>
            </form>
          </div>
        )}
        {isLoading && (
          <div className="h-[370px] md:h-[350px] lg:h-[310px] flex items-center justify-center">
            <Spinner className="w-40 h-40" />
          </div>
        )}
        {isSuccess && (
          <div className="h-auto px-24 md:px-40">
            <h2 className="text-32 font-bold text-center mb-16">
              Thank you for filling out your information!
            </h2>
            {getValues("contact-details").includes("@") ? (
              <>
                <p className="text-menu-white text-center">
                  We will review your submission in the next 48h. You will receive a confirmation at
                  your:
                </p>
                <p className="text-18 font-bold text-center mt-32">
                  {getValues("contact-details")}
                </p>
              </>
            ) : (
              <p className="text-menu-white text-center">
                We will review your submission in the next 48h.
              </p>
            )}
            <Link
              to="/dapp-store"
              onClick={() => {
                setSubmitModalOpen(false);
                reset();
              }}
              className="btn-big mt-48 text-center w-full"
            >
              Back to DApp Store
            </Link>
          </div>
        )}
        {isError && (
          <div className="h-[370px] md:h-[300px] px-24 md:px-40">
            <h2 className="text-32 font-bold text-center mb-16">Something went wrong.</h2>
            <p className="text-menu-white text-center">
              We couldn’t process your request at this time. Please review that all the submission
              fields are completed and try again.
            </p>
            <Link
              to="/support"
              onClick={() => {
                setSubmitModalOpen(false);
                reset();
              }}
              className="btn-big mt-48 text-center w-full"
            >
              Contact Us
            </Link>
          </div>
        )}
      </BaseModal>
    </>
  );
};
export default SubmitModal;

const ErrorMessage = ({ message, className }: { message?: string; className?: string }) => {
  return message ? (
    <p className={classNames("text-[#e77e7e] flex items-center gap-8 pt-6 pb-8", className)}>
      <svg
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-16 h-16"
        role="alert"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
        />
      </svg>
      <span className="text-14">{message}</span>
    </p>
  ) : null;
};

// Will unify after rebasing branch
export const Spinner = ({ className }: { className?: string }) => (
  <svg
    role="status"
    className={classNames("inline h-24 w-24 animate-spin fill-white text-[#e5e7eb]", className)}
    viewBox="0 0 100 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
      fill="currentColor"
    />
    <path
      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
      fill="currentFill"
    />
  </svg>
);
