export const normalizeString = (s: string): string =>
  s.charAt(0).toLowerCase() + s.substring(1).split("-").join("_");

type NormalizedObject = { [key: string]: any };

export const normalizeObjectKeys = <T extends NormalizedObject>(obj: T): T | T[] => {
  const reducerFn = (obj: T) => {
    return Object.keys(obj).reduce((acc, key) => {
      if (
        typeof obj[key] === "object" &&
        !(Array.isArray(obj[key]) && obj[key].find((c: any) => typeof c !== "object"))
      ) {
        // traverse only into arrays of objects, not arrays of strings
        acc[normalizeString(key) as keyof T] = normalizeObjectKeys(obj[key]);
      } else {
        acc[normalizeString(key) as keyof T] = obj[key];
      }

      return acc;
    }, {} as T);
  };

  return Array.isArray(obj) ? obj.map((subArray) => reducerFn(subArray)) : reducerFn(obj);
};
