import React from "react";
import { Dialog } from "@headlessui/react";
import { useCookies } from "react-cookie";

const DAPP_STORE_CONSENT_COOKIE = "dapp_store_consent";

export default function TermsOfServiceModal() {
  const [cookies, setCookie] = useCookies([DAPP_STORE_CONSENT_COOKIE]);
  const [checked, setChecked] = React.useState(false);
  const [isOpenModal, setIsOpenModal] = React.useState(
    () => cookies[DAPP_STORE_CONSENT_COOKIE] == null
  );

  return (
    <Dialog className="relative z-50" open={isOpenModal} onClose={() => {}}>
      <div className="fixed inset-0 bg-blue-bg-dark opacity-90" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="max-w-[660px] w-full bg-blue-filter px-16 md:px-24 py-24 md:pt-36 md:pb-24 md:rounded-md md:shadow-gallery">
          <div className="flex items-center justify-center gap-8 mb-24">
            <div aria-hidden="true" className="text-white flex justify-center items-center">
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                height="1.6em"
                width="1.6em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                <line x1="12" y1="9" x2="12" y2="13"></line>
                <line x1="12" y1="17" x2="12.01" y2="17"></line>
              </svg>
            </div>
            <Dialog.Title as="h1" className="text-24 text-center">
              Terms Of Service
            </Dialog.Title>
          </div>
          <Dialog.Description className="text-center mb-28">
            Before using Milkomeda DApp Store, please take a moment to carefully review and accept
            Milkomeda Terms of Service.
          </Dialog.Description>

          <div className="mt-12 flex items-start md:items-center justify-center gap-10 mb-6">
            <input
              id="tos-consent"
              type="checkbox"
              className="form-checkbox form-checkbox--square hover:cursor-pointer relative top-[2px] sm:top-0 w-20 h-20"
              value="Consent to Terms of Use"
              onChange={() => {
                setChecked(!checked);
              }}
              checked={checked}
              required
            />

            <label htmlFor="tos-consent" className="text-14">
              I have read, understand and consent to the{" "}
              <a
                href="https://milkomeda.com/terms-of-use/"
                className="underline mx-4"
                target="_blank"
              >
                Terms of Service.
              </a>
            </label>
          </div>
          <button
            className="block w-full mx-auto max-w-[145px] p-13 bg-orange text-center rounded-full mt-12"
            disabled={!checked}
            onClick={() => {
              setCookie(DAPP_STORE_CONSENT_COOKIE, true, { path: "/" });
              setIsOpenModal(false);
            }}
          >
            Accept
          </button>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
